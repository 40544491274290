import { flow, makeAutoObservable } from 'mobx';
import gql from 'graphql-tag';
import { groupBy, omit, prop } from 'ramda';

const INVENTORY_QUERY = gql`
  query inventories($warehouseAccountIds: [ID!]!) {
    inventories(warehouseAccountIds: $warehouseAccountIds) {
      inventories {
        warehouseAccountId
        sku_id
        sku_name
        sku_code
        warehouse_code
        available_stock
        pending_stock
        onway_stock
        width
        length
        height
        weight
        batches {
          stockAge {
            warehouse_stock
            inventory_age
            putaway_time
          }
        }
      }
    }
  }
`;

const UOMS_QUERY = gql`
  query uoms($warehouseAccountId: ID!) {
    uoms(warehouseAccountId: $warehouseAccountId) {
      code
      name_cn
      name_en
      symbol
    }
  }
`;

const ADD_SKU = gql`
  mutation addSku($sku: SkuInput!, $warehouseAccountId: ID!) {
    addSku(sku: $sku, warehouseAccountId: $warehouseAccountId) {
      sku_code
      sku_id
    }
  }
`;

const ADD_BATCH_SKU = gql`
  mutation addBatchSku($skus: [SkuInput!]!, $warehouseAccountId: ID!) {
    addBatchSku(skus: $skus, warehouseAccountId: $warehouseAccountId) {
      sku_code
      sku_id
    }
  }
`;

const SYNC_SKU = gql`
  mutation syncSkus($warehouseAccountId: ID!) {
    syncSkus(warehouseAccountId: $warehouseAccountId)
  }
`;

const MODIFY_SKU_INFO = gql`
  mutation modifySkuInfo(
    $warehouseAccountId: ID!
    $sku_id: ID!
    $length: Float
    $width: Float
    $height: Float
    $weight: Float
    $product_code: ID
    $uom: String
    $declare_country_list: [DeclareCountryInput!]
  ) {
    modifySkuInfo(
      warehouseAccountId: $warehouseAccountId
      sku_id: $sku_id
      length: $length
      width: $width
      height: $height
      weight: $weight
      product_code: $product_code
      uom: $uom
      declare_country_list: $declare_country_list
    ) {
      sku_id
    }
  }
`;

const SEARCH_SKU = gql`
  query inventories($warehouseAccountId: ID!, $barcode: String!) {
    inventories(warehouseAccountIds: [$warehouseAccountId], searchTerm: $barcode) {
      inventories {
        sku_id
        sku_code
        weight
        length
        height
        width
        sku_name
      }
    }
  }
`;

export class InventoryStore {
  cachedItems = null;
  loading = false;
  error = null;

  loadingUoms = false;
  getUomsError = null;
  cachedUomsByWarehouseAccountId = null;

  syncSkuError = null;
  addSkuError = null;
  warehouseAccountStore = null;

  constructor(client, warehouseAccountStore) {
    this.client = client;
    this.warehouseAccountStore = warehouseAccountStore;
    makeAutoObservable(
      this,
      {
        fetchItems: flow,
        fetchUoms: flow,
        addItem: flow,
        addItems: flow,
        syncSkus: flow,
        modifySkuInfo: flow,
        client: false,
        warehouseAccountStore: false,
      },
      { autoBind: true },
    );
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return this.cachedItems || [];
  }

  getUoms(warehouseAccountId) {
    if (!this.cachedUomsByWarehouseAccountId) this.cachedUomsByWarehouseAccountId = new Map();

    if (!this.cachedUomsByWarehouseAccountId.has(warehouseAccountId)) {
      this.fetchUoms(warehouseAccountId);
    }

    return this.cachedUomsByWarehouseAccountId.get(warehouseAccountId) || [];
  }

  *fetchUoms(warehouseAccountId) {
    this.loadingUoms = true;
    try {
      const { data } = yield this.client.query({
        query: UOMS_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          warehouseAccountId,
        },
      });
      this.cachedUomsByWarehouseAccountId.set(warehouseAccountId, data.uoms);
    } catch (error) {
      this.getUomsError = error;
    } finally {
      this.loadingUoms = false;
    }
  }

  getItemsByWarehouseAccount(warehouseAccountId) {
    const groupedItems = groupBy(prop('warehouseAccountId'), this.items);
    return groupedItems[warehouseAccountId] || [];
  }

  *fetchItems() {
    this.loading = true;
    try {
      const { data } = yield this.client.query({
        query: INVENTORY_QUERY,
        fetchPolicy: 'no-cache',
        variables: {
          warehouseAccountIds: this.warehouseAccountStore.warehouseAccountIds,
        },
      });
      this.cachedItems = data.inventories?.inventories;
    } catch (error) {
      this.error = error;
    } finally {
      this.loading = false;
    }
  }

  *addItem(warehouseAccountId, sku) {
    this.loading = true;
    this.addSkuError = null;
    try {
      const errors = checkSkuExportInfo(sku);
      if (errors.length > 0) {
        throw new Error(errors.join('\n'));
      }
      const { data } = yield this.client.mutate({
        mutation: ADD_SKU,
        variables: {
          sku: {
            ...omit(['warehouseAccountId'], sku),
            // convert kg into g
            weight: Number(sku.weight) * 1000,
            height: Number(sku.height),
            width: Number(sku.width),
            length: Number(sku.length),
          },
          warehouseAccountId,
        },
      });
      this.cachedItems = null;
      return data.addSku;
    } catch (error) {
      this.addSkuError = error;
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *addItems(warehouseAccountId, skus) {
    skus.forEach((sku, i) => {
      const errors = checkSkuExportInfo(sku);
      if (errors.length > 0) {
        errors.unshift(`SKU ${i + 1}:`);
        throw new Error(errors.join('\n'));
      }
    });

    const MAX_BATCH_SIZE = 50;
    let ret = [];
    let errors = [];

    for (let i = 0; i < skus.length; i += MAX_BATCH_SIZE) {
      try {
        const batch = skus.slice(i, i + MAX_BATCH_SIZE);
        const { data } = yield this.client.mutate({
          mutation: ADD_BATCH_SKU,
          variables: {
            skus: batch.map((sku) => ({
              ...omit(['warehouseAccountId'], sku),
              // convert kg into g
              weight: Number(sku.weight) * 1000,
              height: Number(sku.height),
              width: Number(sku.width),
              length: Number(sku.length),
            })),
            warehouseAccountId,
          },
        });
        this.cachedItems = null;
        ret = ret.concat(data.addBatchSku);
      } catch (error) {
        errors = errors.concat(error.message || error);
      }
    }

    if (errors.length > 0) {
      throw new Error(errors);
    }
    return ret;
  }

  get stock() {
    const stock = this.items.reduce((s, inv) => {
      s[inv.sku_code] = inv.available_stock;
      return s;
    }, {});
    return stock;
  }

  *syncSkus(warehouseAccountId) {
    try {
      this.syncSkuError = null;
      const res = yield this.client.mutate({
        mutation: SYNC_SKU,
        variables: {
          warehouseAccountId,
        },
      });
      this.cachedItems = null;
      this.fetchItems();
      return res;
    } catch (error) {
      this.syncSkuError = error;
      throw error;
    } finally {
      this.loading = false;
    }
  }

  *modifySkuInfo({
    warehouseAccountId,
    skuId,
    length,
    width,
    height,
    weight,
    product_code,
    uom,
    declare_country_list,
  }) {
    try {
      const { data } = yield this.client.mutate({
        mutation: MODIFY_SKU_INFO,
        variables: {
          warehouseAccountId,
          sku_id: skuId,
          length: parseFloat(length),
          width: parseFloat(width),
          height: parseFloat(height),
          weight: parseFloat(weight),
          product_code,
          uom,
          declare_country_list,
        },
      });
      const sku = data.modifySkuInfo || null;
      return sku;
    } catch (error) {
      throw error;
    }
  }

  *searchSku(warehouseAccountId, barcode) {
    try {
      const { data } = yield this.client.query({
        query: SEARCH_SKU,
        variables: {
          warehouseAccountId,
          barcode,
        },
        fetchPolicy: 'no-cache',
      });
      const sku = data.inventories?.inventories?.[0] || null;
      return sku;
    } catch (error) {
      throw error;
    }
  }
}
const checkSkuExportInfo = (sku) => {
  const errors = [];
  sku.declare_country_list.forEach((d) => {
    if (!d.export_port) {
      errors.push('Export port');
    }
    if (!d.export_declare) {
      errors.push('Export declaration');
    }
    if (!d.export_hscode) {
      errors.push('Export HS-CODE');
    }
    if (!d.export_declare_value) {
      errors.push('Export declaration value');
    }
    if (!d.export_currency) {
      errors.push('Export declaration currency');
    }
    if (!d.import_port) {
      errors.push('Import port');
    }
    if (!d.import_declare) {
      errors.push('Import declaration');
    }
    if (!d.hs_code) {
      errors.push('Import HS-CODE');
    }
  });

  if (errors.length > 0) {
    errors.unshift('The following fields are required');
  }
  return errors;
};
