import { makeAutoObservable } from 'mobx';
import { flatten, sum } from 'ramda';
import { unixMillisecondToDate } from '../core/utils/utils';
import { gql } from '@apollo/client';
import { SHIPMENT_ORDER_STATE_MAP } from '@ezom/library/lib/cjs/constants';

const DEFAUL_PARAMS = {
  refNumber: undefined,
  id: undefined,
  shippingNumber: undefined,
  status: undefined,
  sku_code: undefined,
  courier: undefined,
  sales_no: undefined,
  country: undefined,
  create_time_start: undefined,
  create_time_end: undefined,
  page_no: undefined,
  page_size: undefined,
};

const SHIPMENT_ORDER_QUERY = gql`
  query listShipmentOrders(
    $warehouseAccountId: ID!
    $refNumbers: [ID!]
    $ids: [ID!]
    $status: ShipmentOrderStatus
    $saleNumbers: [String!]
    $create_time_start: Float
    $create_time_end: Float
    $page_no: Int
    $page_size: Int
  ) {
    listShipmentOrders(
      warehouseAccountId: $warehouseAccountId
      refNumbers: $refNumbers
      ids: $ids
      status: $status
      saleNumbers: $saleNumbers
      create_time_start: $create_time_start
      create_time_end: $create_time_end
      page_no: $page_no
      page_size: $page_size
    ) {
      shipmentOrders {
        id
        ref_no
        status
        total_volume
        total_weight
        sales_no
        create_time
        update_time
        note
        error
        pickup_country
        pickup_state
        pickup_city
        pickup_district
        pickup_post_code
        pickup_street
        pickup_house_number
        pickup_company
        pickup_name
        pickup_phone
        pickup_email
        receiver_country
        receiver_state
        receiver_city
        receiver_district
        receiver_post_code
        receiver_street
        receiver_house_number
        receiver_company
        receiver_name
        receiver_phone
        receiver_email
        shipments {
          id
          courierConsignmentId
          tracking_number
          courier
          logistics_provider
          items {
            weight
            length
            width
            height
            sku
            qty
            description
            commodity
          }
        }
      }
      total
      page_no
      page_size
    }
  }
`;

export class CourierShipmentTableStore {
  filterParams = DEFAUL_PARAMS;
  sortOption = null;
  searchTerm = '';
  loading = true;
  error = null;
  cachedItems = null;
  exportedItems = null; // Store items for exporting csv
  currentPage = 0;
  pageSize = 20;
  total = null;
  csvModalOpen = false;
  batchSubmitShipmentOpen = false;
  selectedWarehouseAccount = null;
  onWarehouseSelectionSubmit = () => {};

  DISPLAY_KEYS = ['id', 'ref_no', 'sales_no', 'status', 'create_time', 'courier', 'shipments'];

  TITLE_BY_KEY = {
    id: 'ID',
    ref_no: 'Ref No.',
    sales_no: 'Sales No.',
    status: 'Status',
    courier: 'Courier',
    create_time: 'Created Time',
    shipments: 'Tracking',
  };

  get FORMATTER_BY_KEY() {
    return {
      status: (s) => SHIPMENT_ORDER_STATE_MAP[s],
      courier: (s, o) => o.shipments.map((s) => s.courier).join(','),
      create_time: unixMillisecondToDate,
      shipments: (s) => s?.map((s) => s.tracking_number).join('\n'),
    };
  }

  constructor(client, courierShipmentStore) {
    this.dataStore = courierShipmentStore;
    this.client = client;
    makeAutoObservable(
      this,
      {
        dataStore: false,
        client: false,
      },
      { autoBind: true },
    );
  }

  setSelectedWarehouseAccount = (warehouseAccount) => {
    this.selectedWarehouseAccount = warehouseAccount;
  };

  openSearch = () => {
    this.searchOpen = true;
  };

  closeSearch = () => {
    this.searchOpen = false;
  };

  toggleBatchSubmitShipmentModal = (visible) => {
    this.batchSubmitShipmentOpen = visible;
  };

  openCsvModal = () => {
    this.csvModalOpen = true;
  };

  closeCsvModal = () => {
    this.csvModalOpen = false;
  };

  setFilterParams = (params) => {
    this.filterParams = params;
    this.cachedItems = null;
  };

  resetFilterParams = () => {
    this.filterParams = DEFAUL_PARAMS;
    this.cachedItems = null;
    this.searchTerm = '';
  };

  setSortOption = (option) => {
    this.sortOption = option;
  };

  setSearchTerm = (term) => {
    this.searchTerm = term;
    this.cachedItems = null;
  };

  setCurrentPage(page) {
    this.currentPage = page;
    this.cachedItems = null;
  }

  setPageSize = (pageSize) => {
    this.pageSize = pageSize;
    this.cachedItems = null;
  };

  async fetchItems(forExport = false) {
    try {
      this.loading = true;
      if (this.searchTerm) {
        let total = 0;
        let consignments = [];
        for await (let idField of ['ids', 'refNumbers', 'saleNumbers']) {
          const { data } = await this.client.query({
            query: SHIPMENT_ORDER_QUERY,
            variables: {
              ...this.filterParams,
              create_time_start: this.filterParams.create_time_start?.getTime(),
              create_time_end: this.filterParams.create_time_end?.getTime(),
              [idField]: [this.searchTerm],
              warehouseAccountId: this.selectedWarehouseAccount?.id,
            },
            fetchPolicy: 'no-cache',
          });
          consignments = consignments.concat(data.listShipmentOrders.shipmentOrders);
          total += data.listShipmentOrders.total;
        }

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = consignments;
        else this.cachedItems = consignments;

        this.total = total;
      } else {
        let variables = {
          ...this.filterParams,
          warehouseAccountId: this.selectedWarehouseAccount?.id,
          ids: this.filterParams?.id ? [this.filterParams.id] : undefined,
          refNumbers: this.filterParams?.refNumber ? [this.filterParams.refNumber] : undefined,
          create_time_start: this.filterParams.create_time_start?.getTime(),
          create_time_end: this.filterParams.create_time_end?.getTime(),
        };

        if (!forExport) {
          variables.page_size = this.pageSize;
          variables.page_no = this.currentPage + 1;
        }

        const {
          data: { listShipmentOrders },
        } = await this.client.query({
          query: SHIPMENT_ORDER_QUERY,
          variables,
          fetchPolicy: 'no-cache',
        });

        // Assigns the fetched item to either the `exportedItems` or `cachedItems` property
        // Depending on whether the data is being fetched for export or not.
        if (forExport) this.exportedItems = listShipmentOrders.shipmentOrders;
        else this.cachedItems = listShipmentOrders.shipmentOrders;

        this.total = listShipmentOrders.total;
      }
      this.error = null;
    } catch (error) {
      this.error = error;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  get items() {
    if (!this.cachedItems) {
      this.fetchItems();
    }
    return (this.cachedItems || []).map((oc) => ({
      ...oc,
      numOfItems: sum(flatten(oc.shipments.map((s) => s.items.map((item) => item.qty)))),
    }));
  }
}
