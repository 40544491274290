import React, { memo } from 'react';

import { weightGramToKg } from '@ezom/library/lib/cjs/utils';
import { formatDate } from '@ezom/library/lib/cjs/date-utils';
import { Card as KittenCard } from '@ui-kitten/components/ui/card/card.component';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import { omit } from 'ramda';
import ReactJson from 'react-json-view';
import Table from 'src/components/Table';
import TransactionControl from 'src/components/TransactionControl';
import { client, warehouseAccountStore } from 'src/store';
import exportCsv from '../../core/csv';
import { Header } from './shared';

const displayKeys = ['id', 'refNo', 'type', 'amount', 'createdTime', 'remark', 'status'];

const titleByKey = {
  id: 'ID',
  type: 'Type',
  createdTime: 'Created Time',
  remark: 'Remark',
  amount: 'Amount',
  'createdByUser.id': 'User ID',
  isManuallyCreated: 'Manual adjustment',
  status: 'Status',

  refNo: 'Consignment Number / Ref. No.',
};

const KEY_TO_REMOVE = '__typename';
const getAllKeys = (a) => {
  if (!a) return [];
  const s = new Set(a.flatMap((i) => Object.keys(i)));
  s.delete(KEY_TO_REMOVE);
  return Array.from(s);
};

const deprecatedOCTransactionCheck = (t) => t.remark.indexOf('outbound order') >= 0;

const OUTBOUND_CONSIGNMENTS = gql`
  query outbounds($warehouseAccountIds: [ID!]!, $consignmentNumbers: [ID!]!) {
    outbounds(warehouseAccountIds: $warehouseAccountIds, consignmentNumbers: $consignmentNumbers) {
      consignments {
        consignment_no
        ref_no
        shipping_no
        billing_weight
        post_code
      }
    }
  }
`;

const TransactionHistory = ({
  transactions,
  displayExtra,
  transactionParams,
  onChangeTransactionParams,
  defaultParams,
  total,
  getExportData,
}) => {
  const { pageNo, pageSize } = transactionParams;
  const displayTransactions = transactions.map((t) => ({
    ...t,
    refNo: t.remarkExtra
      ? t.remarkExtra.shipmentOrderId ||
        t.remarkExtra.storageFeeId ||
        t.remarkExtra.outboundConsignmentNumber ||
        t.remarkExtra.inboundConsignmentNumber
      : '',

    status: t.status ? t.status : 'Instantly completed',
    createdTime: formatDate(new Date(Number(t.createdTime))),
    updatedTime: t.updatedTime && formatDate(new Date(Number(t.updatedTime))),
  }));

  const displayKeysSet = new Set(
    displayExtra ? displayKeys.concat(['createdByUser.id']) : displayKeys,
  );

  const setPageSize = (size) => {
    onChangeTransactionParams?.({ ...transactionParams, pageSize: size, pageNo: 0 });
  };

  const removeTypename = (row) => omit([KEY_TO_REMOVE], row);
  return (
    <KittenCard header={(props) => <Header {...props} heading="Transaction history" />} disabled>
      <TransactionControl
        filterParams={transactionParams}
        indicators={getIndicators(transactionParams)}
        onFilterChange={(newVal) =>
          onChangeTransactionParams?.({ ...transactionParams, ...newVal, pageNo: 0 })
        }
        onFilterClear={() => onChangeTransactionParams?.(defaultParams)}
        onSearchTermChange={(s) =>
          onChangeTransactionParams?.({ ...transactionParams, searchTerm: s, pageNo: 0 })
        }
      />
      <Table
        itemsPerPage={pageSize}
        setPageSize={setPageSize}
        heightOffset={400}
        totalItemNum={total}
        currentPage={pageNo}
        onPageChange={(page) => {
          onChangeTransactionParams({ ...transactionParams, pageNo: page });
        }}
        sort="single"
        items={displayTransactions}
        displayKeys={Array.from(displayKeysSet)}
        titleByKey={titleByKey}
        rowOnClickContent={(row) => {
          return (
            <ReactJson
              src={removeTypename(row)}
              collapsed={false}
              name={null}
              displayDataTypes={false}
            />
          );
        }}
        fabActions={(selectedIds) => {
          const actions = [];
          const hasSelected = selectedIds && selectedIds.size > 0;
          actions.push({
            icon: 'download-outline',
            label: `Export ${hasSelected ? 'selected' : 'all'} to CSV`,
            onPress: async () => {
              const data = await getExportData({
                listId: selectedIds && selectedIds.size > 0 ? Array.from(selectedIds) : undefined,
              });
              const inboundOrOutboundTransactions = [];
              let storageFeeTransactions = [];
              const otherTransactions = [];

              for (let i = 0; i < data.length; i++) {
                const t = data[i];

                if (
                  t.remark.indexOf('inbound order') >= 0 ||
                  deprecatedOCTransactionCheck(t) ||
                  (t.remarkExtra &&
                    (t.remarkExtra.inboundConsignmentNumber ||
                      t.remarkExtra.outboundConsignmentNumber))
                ) {
                  inboundOrOutboundTransactions.push({
                    ...t,
                    'SKUs In The Order': t.remarkExtra.skuCodes,
                  });
                } else if (t.remark.indexOf('storage fee') >= 0) {
                  const charges =
                    t.remarkExtra && t.remarkExtra.charges
                      ? t.remarkExtra.charges.flatMap((charge) =>
                          charge.remark
                            ? charge.remark.map((r) => {
                                const tmp = { ...r };
                                tmp['Putaway Time'] = formatDate(new Date(tmp['Putaway Time']));
                                return {
                                  'Ref No': t.refNo,
                                  'Fee Name': t.remark,
                                  'Billing Date': t.createdTime,
                                  ...tmp,
                                };
                              })
                            : [],
                        )
                      : [];

                  storageFeeTransactions = storageFeeTransactions.concat(charges);
                } else {
                  otherTransactions.push(t);
                }
              }

              const exportToCsv = (trans, fileName) => {
                if (trans && trans.length > 0) {
                  exportCsv(
                    trans.map((t) => ({
                      ...t,
                      remarkExtra: t.remarkExtra ? JSON.stringify(t.remarkExtra) : '',
                    })),
                    getAllKeys(trans),
                    fileName,
                    {},
                  );
                }
              };

              exportToCsv(storageFeeTransactions, 'storage_details.csv');
              exportToCsv(otherTransactions, 'other_transactions.csv');

              const ocNumbers = inboundOrOutboundTransactions
                .map((t) => {
                  if (t.remarkExtra && t.remarkExtra.outboundConsignmentNumber) {
                    return t.remarkExtra.outboundConsignmentNumber;
                  } else if (deprecatedOCTransactionCheck(t)) {
                    return t.remarkExtra.consignmentNumber;
                  }

                  return undefined;
                })
                .filter((t) => t !== undefined);

              if (ocNumbers.length) {
                const {
                  data: { outbounds },
                } = await client.query({
                  query: OUTBOUND_CONSIGNMENTS,
                  variables: {
                    consignmentNumbers: ocNumbers,
                    warehouseAccountIds: warehouseAccountStore.warehouseAccountIds,
                  },
                  fetchPolicy: 'no-cache',
                });

                const ocDetailByNo = outbounds.consignments.reduce((a, c) => {
                  return a.set(c.consignment_no, c);
                }, new Map());

                exportToCsv(
                  inboundOrOutboundTransactions.map((t) => {
                    const consignmentNumber = t.refNo;
                    delete t.refNo;
                    const tmp = { ...t, 'Consignment Number': consignmentNumber };

                    if (ocDetailByNo.has(consignmentNumber)) {
                      const d = ocDetailByNo.get(consignmentNumber);
                      return {
                        ...tmp,
                        'Ref No': d.ref_no,
                        'Tracking No': d.shipping_no,
                        'Chargeable Weight': weightGramToKg(true)(d.billing_weight),
                        'Post Code': d.post_code,
                      };
                    } else return tmp;
                  }),
                  'order_details.csv',
                );
              }
            },
          });

          return actions;
        }}
      />
    </KittenCard>
  );
};

export default memo(TransactionHistory);

const dateFormatter = (date) => format(date, 'd MMM y');
const getIndicators = (params) => {
  let indicators = [];

  if (params) {
    if (params.type) {
      indicators.push(`type: ${params.type}`);
    }

    if (params.create_time_start && params.create_time_end) {
      indicators.push(
        `transactions from ${dateFormatter(params.create_time_start)} to ${dateFormatter(
          params.create_time_end,
        )}`,
      );
    } else if (params.create_time_start) {
      indicators.push(`transactions from ${dateFormatter(params.create_time_start)}`);
    } else if (params.create_time_end) {
      indicators.push(`transactions by ${dateFormatter(params.create_time_end)}`);
    }
  }

  return indicators;
};
